import React from 'react';
import { Refine, Authenticated, CanReturnType } from '@refinedev/core';
import { ThemedLayoutV2, notificationProvider } from '@refinedev/antd';
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar';
import {
  Route,
  Routes,
  useLocation,
  Navigate,
  Outlet,
  BrowserRouter,
} from 'react-router-dom';
import {
  BuildOutlined,
  BuildTwoTone,
  KeyOutlined,
  SyncOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { ColorModeContextProvider } from './contexts/color-mode';

import '@refinedev/antd/dist/reset.css';
import routerBindings, {
  NavigateToResource,
  UnsavedChangesNotifier,
  CatchAllNavigate,
  RefineRoutes,
} from '@refinedev/react-router-v6';
import * as Settings from './settings';

import { Login } from './pages/login';
import { ProjectOptions } from './pages/builds/by-projects';
import { authProvider } from './providers/authProvider';
import { RoleEdit, RoleShow, RoleCreate, RoleList } from './pages/roles';
import { PipelineEdit, PipelineList } from './pages/pipelines';
import { UserCreate, UserEdit, UserList } from './pages/users';
import { type IRole } from './interfaces/index';
import { CustomBuildList } from './pages/custom-builds';
import { CustomBuildCreate } from './pages/custom-builds/create';
import { BuildShow } from './pages/builds/show';
import { dataProvider } from './rest-data-provider';
import { Header } from './component/header';
import { PipelineOptions } from 'pages/builds/by-pipelines';
import { BuildEdit } from 'pages/builds';
import { ReleaseOptions } from 'pages/builds/releases';
import { BuildCreate } from 'pages/builds/create';

export const App: React.FC = () => {
  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Refine
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            dataProvider={dataProvider(`${Settings.ServerUrl}/build/v1/rest`)}
            authProvider={authProvider}
            accessControlProvider={{
              can: ({ resource }): Promise<CanReturnType> => {
                const rolesJson = localStorage.getItem('roles');
                const roles: IRole[] =
                  rolesJson !== null ? JSON.parse(rolesJson) : rolesJson;
                const canGuest =
                  roles.find(
                    (element) => element.name === 'frn:role:build:general:guest'
                  ) !== undefined;
                const canRead =
                  roles.find(
                    (element) => element.name === 'frn:role:build:general:read'
                  ) !== undefined;
                const canWrite =
                  roles.find(
                    (element) => element.name === 'frn:role:build:general:write'
                  ) !== undefined;
                const canAdmin =
                  roles.find(
                    (element) => element.name === 'frn:role:build:general:admin'
                  ) !== undefined;
                return Promise.resolve(
                  canWith(resource, canGuest, canRead, canWrite, canAdmin)
                );
              },
            }}
            resources={[
              {
                name: 'builds',
                create: 'builds/create',
                show: 'builds/show/:id',
                edit: 'builds/edit/:id',
                list: 'builds/releases',
                icon: <BuildOutlined />,
              },
              {
                name: 'releases',
                parentName: 'builds',
                list: 'builds/releases',
              },
              {
                name: 'by-projects',
                parentName: 'builds',
                list: 'builds/by-projects',
                create : 'builds/create',
              },
              {
                name: 'by-pipelines',
                parentName: 'builds',
                list: 'builds/by-pipelines',
                create : 'builds/create',
              },
              {
                name: 'custom-builds',
                list: 'custom-builds',
                create: 'custom-builds/create',
                icon: <BuildTwoTone />,
              },
              {
                name: 'manage',
                icon: <KeyOutlined />,
              },
              {
                name: 'pipelines',
                list: '/pipelines',
                edit: '/pipelines/edit/:id',
                parentName: 'manage',
                icon: <SyncOutlined />,
              },
              {
                name: 'roles',
                parentName: 'manage',
                list: '/roles',
                create: '/roles/create',
                edit: '/roles/edit/:id',
                show: '/roles/show/:id',
                icon: <UsergroupAddOutlined />,
              },
              {
                name: 'users',
                list: '/users',
                create: '/users/create',
                edit: '/users/edit/:id',
                parentName: 'manage',
                icon: <UserOutlined />,
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
            }}
          >
            <RefineRoutes>
              {(resourceRoutes) => (
                <Routes>
                  <Route
                    element={
                      <Authenticated
                        fallback={<CatchAllNavigate to="/login" />}
                      >
                        <ThemedLayoutV2
                          Header={Header}
                          Title={({ collapsed }) => (
                            <div style={{ display: 'block', margin: 'auto' }} >
                              {collapsed || (
                                <img
                                  src="/fossil-logo.png"
                                  alt="logo"
                                  width="128px"
                                />
                              )}
                            </div>
                          )}
                        >
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }
                  >
                    {resourceRoutes}
                    <Route
                      index
                      element={<NavigateToResource resource="builds" />}
                    />
                    <Route path="/builds">
                      <Route index element={<ReleaseOptions />} />
                      <Route path="create" element={<BuildCreate />} />
                      <Route path="show/:id" element={<BuildShow />} />
                      <Route path="edit/:id" element={<BuildEdit />} />
                    </Route>
                    <Route path="builds/releases">
                      <Route index element={<ReleaseOptions />} />
                    </Route>
                    <Route path="builds/by-projects">
                      <Route index element={<ProjectOptions />} />
                    </Route>
                    <Route path="builds/by-pipelines">
                      <Route index element={<PipelineOptions />} />
                    </Route>
                    <Route path="/custom-builds">
                      <Route index element={<CustomBuildList />} />
                      <Route path="create" element={<CustomBuildCreate />} />
                    </Route>
                    <Route path="/pipelines">
                      <Route index element={<PipelineList />} />
                      <Route path="edit/:id" element={<PipelineEdit />} />
                    </Route>
                    <Route path="/roles">
                      <Route index element={<RoleList />} />
                      <Route path="edit/:id" element={<RoleEdit />} />
                      <Route path="create" element={<RoleCreate />} />
                      <Route path="show/:id" element={<RoleShow />} />
                    </Route>
                    <Route path="/users">
                      <Route index element={<UserList />} />
                      <Route path="create" element={<UserCreate />} />
                      <Route path="edit/:id" element={<UserEdit />} />
                    </Route>
                  </Route>
                  <Route
                    element={
                      <Authenticated fallback={<Outlet />}>
                        <NavigateToResource />
                      </Authenticated>
                    }
                  >
                    <Route path="/login" element={<Login />} />
                  </Route>
                </Routes>
              )}
            </RefineRoutes>
            <RefineKbar />
            <UnsavedChangesNotifier />
            <Routes>
              <Route path="*" element={<HashRouteWrapper />} />
            </Routes>
          </Refine>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  );

  function canWith(
    resource: string | undefined,
    canGuest: boolean,
    canRead: boolean,
    canWrite: boolean,
    canAdmin: boolean
  ): CanReturnType {
    switch (resource) {
    case 'builds':
      if (location.pathname === '/builds/create') {
        return {
          can: canAdmin,
        };
      } else {
        return {
          can: canGuest || canRead || canWrite || canAdmin,
        };
      }
    case 'releases':
      return {
        can: canGuest || canRead || canWrite || canAdmin,
      };
    case 'by-projects':
    case 'by-pipelines':
      return {
        can: canRead || canWrite || canAdmin,
      };
    case 'custom-builds':
      return {
        can: canWrite || canAdmin,
      };
    case 'pipelines':
    case 'manage':
      return {
        can: canWrite || canAdmin,
      };
    case 'roles':
      return {
        can: canWrite || canAdmin,
      };
    case 'users':
      return { can: canAdmin };
    default:
      return { can: true };
    }
  }
}

function HashRouteWrapper(): JSX.Element | null {
  const location = useLocation();
  if (location.hash.startsWith('#/build-detail')) {
    return (
      <Navigate
        to={location.hash.replace('#/build-detail', 'builds/show')}
        replace
      />
    );
  }
  return null;
}

export default App;
