import { CloudDownloadOutlined } from '@ant-design/icons';
import {
  List,
  TextField,
  getDefaultSortOrder,
  useTable,
} from '@refinedev/antd';
import {
  usePermissions,
  type CrudFilters,
  type HttpError,
} from '@refinedev/core';
import { useModalForm } from '@refinedev/react-hook-form';
import {
  Button,
  Form,
  Modal,
  Space,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import type React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  IPipeline,
  type IBuild,
  IPipeLineFilterVariables
} from '../../../interfaces';
import { DownloadLinkList } from '../../download-links/list';
import { NameFilter } from '../common/name_filter';

export const ReleaseOptions: React.FC = () => {
  return <ReleaseBuildList />
}

const ReleaseBuildList: React.FC = () => {
  const { tableProps, sorters, searchFormProps } = useTable<
    IPipeline,
    HttpError,
    IPipeLineFilterVariables
  >({
    resource: 'pipelines/latest',
    syncWithLocation: true,
    initialPageSize: 1000,
    initialFilter: [
      {
        field: 'name',
        operator: 'eq',
        value: '',
      },
    ],
    onSearch: (params: IPipeLineFilterVariables) => {
      const filters: CrudFilters = [];
      const { name } = params;

      filters.push({
        field: 'name',
        operator: 'eq',
        value: name,
      });

      return filters;
    },
  });

  const [download, setDownload] = useState<IPipeline>()

  const {
    modal: {
      visible: downloadDialogVisible,
      close: downloadDialogClose,
      show: downloadDialogShow,
    },
  } = useModalForm();

  const { data: permissionsData } = usePermissions<string[]>()
  let canRead = false
  let canAdmin = false
  if (permissionsData !== undefined) {
    canAdmin = permissionsData.find((e) => e === 'frn:role:build:general:admin') !== undefined
    canRead = canAdmin || permissionsData.find((e) => e === 'frn:role:build:general:read' || e === 'frn:role:build:general:write') !== undefined
  }

  return (
    <>
      <List canCreate={canAdmin} title=<Form {...searchFormProps} key="filter">
      </Form>
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="latestBuild"
            key="build_uuid"
            title="Build UUID"
            render={(value: IBuild) =>
              canRead ? (
                <Link to={`/builds/show/${value?.buildUUID}`}>
                  {value?.buildUUID}
                </Link>
              ) : (
                <TextField value={value?.buildUUID} />
              )
            }
          />
          <Table.Column
            dataIndex="name"
            key="name"
            title="Pipeline"
            render={(value) => <TextField value={value} />}
            sortOrder={getDefaultSortOrder('name', sorters)}
            filterDropdown={<NameFilter formProps={searchFormProps} />}
          />
          <Table.Column
            dataIndex="latestBuild"
            key="name"
            title="Build Name"
            render={(value: IBuild) => <TextField value={value?.name} />}
          />
          <Table.Column
            dataIndex="latestBuild"
            key="meta_build_id"
            title="Metadata Build ID"
            render={(value: IBuild) => (
              <TextField value={value?.metadataBuildId} />
            )}
          />
          <Table.Column
            dataIndex="latestBuild"
            key="versionCode"
            title="Version Code"
            render={(value: IBuild) => (
              <Tag color="red">{value?.versionCode}</Tag>
            )}
          />
          <Table.Column
            dataIndex="latestBuild"
            key="created_at"
            title="Created"
            render={(value: IBuild) => (
              <TextField value={new Date(value?.createdAt).toLocaleString()} />
            )}
          />
          <Table.Column<IPipeline>
            dataIndex="actions"
            title="Actions"
            fixed="right"
            render={(_, record) => (
              <Space>
                <Tooltip title={'Download'}>
                  <Button
                    icon=<CloudDownloadOutlined />
                    onClick={handleDownload(record)}
                  />
                </Tooltip>
              </Space>
            )}
          />
        </Table>
      </List>

      <Modal
        visible={downloadDialogVisible}
        onOk={downloadDialogClose}
        onCancel={downloadDialogClose}
        footer={[
          <Button key="close" type="primary" onClick={downloadDialogClose}>
            CLOSE
          </Button>,
        ]}
      >
        {download != null && (
          <DownloadLinkList
            id={download.latestBuild.id}
            token={download.latestBuild.token}
            buildName={download.latestBuild.name}
            pipeline={download.name}
          />
        )}
      </Modal>
    </>
  );

  function handleDownload(pipeline: IPipeline): React.MouseEventHandler<HTMLElement> | undefined {
    return () => {
      setDownload(pipeline);
      downloadDialogShow()
    }
  }
}
