import { IResourceComponentsProps, useNavigation } from '@refinedev/core';
import { Edit, useForm, useSelect } from '@refinedev/antd';

import { Checkbox, Col, Form, Input, Row, Select } from 'antd';

import { IRole, IPipeline, IPlatform } from 'interfaces';
import React from 'react';

export const PipelineEdit: React.FC<IResourceComponentsProps> = () => {
  const { goBack } = useNavigation();
  const { formProps, saveButtonProps, formLoading } = useForm<IPipeline>({
    redirect: false,
    onMutationSuccess: () => {
      goBack();
    },
  });

  const {
    selectProps: platformSelectProps,
    queryResult: platformQueryResult,
  } = useSelect<IPlatform>({
    resource: 'platform-options',
    hasPagination: false,
  });

  const { selectProps: modeSelectProps, queryResult: modeQueryResult } =
        useSelect<IPlatform>({
          resource: 'build-mode-options',
          hasPagination: false,
        });

  const { selectProps: groupSelectProps } = useSelect<IRole>({
    pagination: {
      current: 1,
      pageSize: 10,
      mode: 'server',
    },
    resource: 'roles',
    onSearch: (value: string) => [
      {
        field: 'field',
        operator: 'eq',
        value: 'name',
      },
      {
        field: 'query',
        operator: 'eq',
        value,
      },
    ],
  });

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={
        formLoading ||
                platformQueryResult.isLoading ||
                modeQueryResult.isLoading
      }
    >
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Pipeline"
          name="name"
          rules={[{ required: true }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item label="Project name" name="projectName">
          <Input />
        </Form.Item>
        <Form.Item label="Project branch" name="projectBranch">
          <Input />
        </Form.Item>
        <Row>
          <Col flex={1}>
            <Form.Item
              label="Can Sign"
              name="canSign"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              label="Can OTA"
              name="canOTA"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label="Platform" name="platform">
          <Select
            loading={formLoading}
            {...platformSelectProps}
            defaultValue={formProps.initialValues?.platform}
          />
        </Form.Item>
        <Form.Item label="Build Mode" name="buildMode">
          <Select
            loading={formLoading}
            {...modeSelectProps}
            defaultValue={formProps.initialValues?.buildMode}
          />
        </Form.Item>
        <Form.Item label="Groups" name="groups">
          <Select mode="multiple" {...groupSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
