import type React from 'react';
import { Form, type FormProps, Card, Input, InputRef } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Ref } from 'react';

export const MetadataBuildIDFilter: React.FC<{
  formProps: FormProps;
  refInput?: Ref<InputRef> | undefined;
}> = ({ formProps, refInput }) => (
  <Card style={{ height: '100%' }}>
    <Form layout="vertical" {...formProps}>
      <Form.Item
        name="field"
        initialValue="metadataBuildId"
        style={{ height: '0', margin: '0' }}
      />
      <Form.Item name="query" style={{ marginBottom: '0' }}>
        <Input
          placeholder="Metadata Build ID"
          prefix={<SearchOutlined />}
          ref={refInput}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              formProps.form?.setFieldValue('field', 'metadataBuildId');
              formProps.form?.submit();
            }
          }}
        />
      </Form.Item>
    </Form>
  </Card>
);