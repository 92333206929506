import type React from 'react';
import {
  Modal,
  message,
} from 'antd';
import {
  type IBuild,
  type IPipelineOption,
  type ITagOption,
} from '../../../interfaces';
import { type DefaultOptionType } from 'rc-select/lib/Select';
import { axiosInstance } from '../../../rest-data-provider/utils';

export const sortAlphabet = (a: IPipelineOption, b: IPipelineOption): number => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
    
  return 0;
};
  
export function getTagOption(
  apiUrl: string,
  id: number,
  setTagOptions: React.Dispatch<
          React.SetStateAction<DefaultOptionType[] | undefined>
      >,
): void {
  axiosInstance
    .get<ITagOption>(`${apiUrl}/pipelines/${id}/tag-options`)
    .then((resp) => {
      setTagOptions(
        resp.data.data?.map((value) => ({ value, label: value })),
      );
    })
    .catch(() => message.error('setTagOptions error'));
}

export function handleSign(
  build: IBuild,
  apiUrl: string,
  setSignButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
): React.MouseEventHandler<HTMLElement> | undefined {
  return () => {
    if (build.signStatus === 'Initialize') {
      Modal.info({
        title: 'Sign build',
        content:
                    'Signing process is initializing. Check back later for more information.',
      });
      return;
    }

    if (build.signStatus === 'Building') {
      const url = `${window.env.REACT_APP_JENKINS_BASE_URL}/job/hoki/job/hoki-core-signed-build/${build.signJenkinsId}`;
      Modal.info({
        title: 'Sign build',
        content: (
          <a href={url} target="_blank" rel="noreferrer">
            {url}
          </a>
        ),
      });
      return;
    }

    setSignButtonLoading(true);
    interface ISignBuildResponse {
        build: IBuild;
    }

    axiosInstance
      .post<ISignBuildResponse>(`${apiUrl}/builds/${build.id}/sign`)
      .then((response) => {
        build.signJenkinsId = response.data.build.signJenkinsId;
        build.signUID = response.data.build.signUID;
        build.signStatus = response.data.build.signStatus;
        setSignButtonLoading(false);
        return message.success('Sign build success');
      })
      .catch(() => {
        setSignButtonLoading(false);
        return message.error('Sign build error');
      });
  };
}
