import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  type CrudFilters,
  type HttpError,
  type IResourceComponentsProps,
  type LogicalFilter,
  useApiUrl,
  useList,
  usePermissions,
} from '@refinedev/core';
import {
  List,
  TextField,
  useTable,
  getDefaultSortOrder,
  ShowButton,
  EditButton,
} from '@refinedev/antd';
import {
  Table,
  Select,
  Skeleton,
  Form,
  type SelectProps,
  Space,
  Tag,
  Button,
  Modal,
  Tooltip,
  message,
  DatePicker,
  InputRef,
  Divider,
} from 'antd';
import {
  DownloadOutlined,
} from '@ant-design/icons';
import {
  type IBuild,
  type IBuildFilterVariables,
  type IProjectOption,
  type ITagOption,
} from '../../../interfaces';
import { type DefaultOptionType } from 'rc-select/lib/Select';
import { useModalForm } from '@refinedev/react-hook-form';
import { DownloadLinkList } from '../../download-links/list';
import { axiosInstance } from '../../../rest-data-provider/utils';
import { BuildUUIDFilter, handleSign, MetadataBuildIDFilter, sortAlphabet } from '../common';
import { OtaBuildList } from '../ota';
import { Link } from 'react-router-dom';

const { RangePicker } = DatePicker;

export const ProjectOptions: React.FC<IResourceComponentsProps> = () => {
  const { data, isLoading } = useList<IProjectOption>({
    resource: 'project-options',
    config: {
      hasPagination: false,
    },
  });

  return isLoading ? (
    <Skeleton active />
  ) : data && data.data && data.data.length > 0 ? (
    <ProjectBuildList projectOptions={data.data.sort(sortAlphabet)} />
  ) : (
    <div />
  );
};

interface ProjectBuildListProps {
  projectOptions: IProjectOption[];
}

const ProjectBuildList: React.FC<ProjectBuildListProps> = ({
  projectOptions,
}: ProjectBuildListProps) => {
  const { tableProps, sorters, searchFormProps, filters } = useTable<
    IBuild,
    HttpError,
    IBuildFilterVariables
  >({
    resource: 'builds',
    syncWithLocation: true,
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
    initialFilter: [
      {
        field: 'projectName',
        operator: 'eq',
        value: projectOptions[0].name,
      },
      {
        field: 'projectBranch',
        operator: 'eq',
        value: projectOptions[0].branch,
      },
    ],
    onSearch: (params: IBuildFilterVariables) => {
      const filters: CrudFilters = [];
      const { projectName, projectBranch, tags, field, query, createdAt } = params;

      filters.push(
        {
          field: 'projectName',
          operator: 'eq',
          value: projectName ?? projectOptions[0].name,
        },
        {
          field: 'projectBranch',
          operator: 'eq',
          value: projectBranch ?? projectOptions[0].branch,
        },
        {
          field: 'tags',
          operator: 'eq',
          value: tags,
        },
        {
          field: 'field',
          operator: 'eq',
          value: field,
        },
        {
          field: 'query',
          operator: 'eq',
          value: query,
        },
        {
          field: 'createdAt',
          operator: 'gte',
          value: createdAt ? createdAt[0].toISOString() : undefined,
        },
        {
          field: 'createdAt',
          operator: 'lte',
          value: createdAt ? createdAt[1].toISOString() : undefined,
        },
      );

      return filters;
    },
  });

  const [tagOptions, setTagOptions] = useState<SelectProps['options']>([]);

  const apiUrl = useApiUrl();

  useEffect(() => {
    const option = projectOptions.find(
      (value) =>
        value.name ===
        filters?.find(
          (value) => (value as LogicalFilter).field === 'projectName',
        )?.value
        && value.branch === filters?.find(
          (value) => (value as LogicalFilter).field === 'projectBranch',
        )?.value,
    );
    if (option !== undefined) {
      getTagOption(apiUrl, option.id, setTagOptions);
    }
  }, [apiUrl, filters, projectOptions]);

  const [downloadBuild, setDownloadBuild] = useState<IBuild>();

  const {
    modal: {
      visible: downloadDialogVisible,
      close: downloadDialogClose,
      show: downloadDialogShow,
    },
  } = useModalForm();

  const { data: permissionsData } = usePermissions<string[]>();
  let canWrite = false;
  let canAdmin = false;
  if (permissionsData !== undefined) {
    canAdmin = permissionsData.find((e) => e === 'frn:role:build:general:admin') !== undefined;
    canWrite = canAdmin || permissionsData.find((e) => e === 'frn:role:build:general:write') !== undefined;
  }
  const [signButtonLoading, setSignButtonLoading] = useState(false);

  const {
    modal: {
      visible: otaDialogVisible,
      close: otaloadDialogClose,
      show: otaDialogShow,
    },
  } = useModalForm();
  const [otaBuild, setOtaBuild] = useState<IBuild>();

  const searchInputUUID = useRef<InputRef>(null);
  const searchInputID = useRef<InputRef>(null);

  return (
    <>
      <List canCreate={canAdmin}
        title=<Form {...searchFormProps} key="filter">
          <Space wrap>
            <Form.Item name="projectName" label="Project" key="project">
              <Select
                key="projectSelect"
                showSearch
                style={{ minWidth: '128px' }}
                dropdownMatchSelectWidth={false}
                options={Array.from(
                  new Set(projectOptions.map((value) => value.name))
                ).map((value) => ({ label: value, value }))}
                defaultValue={filterProjectName() ?? projectOptions[0].name}
                onChange={(value) => {
                  const newSelectedOption = projectOptions
                    .filter((o) => o.name === value)
                    .map((value) => value.branch)
                    .sort();
                  if (newSelectedOption.length > 0) {
                    searchFormProps.form?.setFieldsValue({
                      projectName: value,
                      projectBranch: newSelectedOption[0],
                      tags: [],
                    });
                    searchFormProps.form?.submit();
                  }
                }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    {canWrite && <Divider style={{ margin: '8px 0' }} />}
                    {canWrite && <Button
                      type="link"
                      block
                      onClick={() => {
                        searchFormProps.form?.setFieldsValue({
                          projectName: '',
                          projectBranch: '',
                          tags: [],
                        });
                        searchFormProps.form?.submit();
                      }}
                    >
                      All Pipelines
                    </Button>}
                  </>
                )}
              />
            </Form.Item>
            <Form.Item name="projectBranch" label="Branch" key="branch">
              <Select
                key="branchSelect"
                showSearch
                style={{ minWidth: '128px' }}
                dropdownMatchSelectWidth={false}
                options={projectOptions
                  .filter(
                    (value) =>
                      value.name ===
                      filters?.find(
                        (value) =>
                          (value as LogicalFilter).field === 'projectName'
                      )?.value
                  )
                  .map((value) => value.branch)
                  .sort()
                  .map((value) => ({ label: value, value }))}
                defaultValue={
                  filterProjectBranch() ??
                  projectOptions.map((value) => value.branch).sort()[0]
                }
                onChange={(value) => {
                  searchFormProps.form?.setFieldsValue({
                    projectBranch: value,
                  });
                  searchFormProps.form?.submit();
                }}
              />
            </Form.Item>
            <Form.Item name="tags" label="Tags" key="tags">
              <Select
                key="tagsSelect"
                style={{ minWidth: '128px' }}
                showSearch
                mode="multiple"
                dropdownMatchSelectWidth={false}
                options={tagOptions}
                defaultValue={filterTags()}
                onChange={(value) => {
                  searchFormProps.form?.setFieldsValue({
                    tags: value,
                  });
                  searchFormProps.form?.submit();
                }}
              />
            </Form.Item>
            <Form.Item label="Created At" name="createdAt">
              <RangePicker
                onChange={(dates) => {
                  if (dates === null) return;
                  searchFormProps.form?.setFieldsValue({
                    createdAt: [
                      dates[0]?.startOf('day'),
                      dates[1]?.endOf('day'),
                    ],
                  });
                  searchFormProps.form?.submit();
                }}
              />
            </Form.Item>
          </Space>
        </Form>
        breadcrumb={null}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="buildUUID"
            key="buildUUID"
            title="Build UUID"
            render={(value) => (
              <Link to={`/builds/show/${value}`}> {value} </Link>
            )}
            sortOrder={getDefaultSortOrder('buildUUID', sorters)}
            sorter
            filterDropdown={() => {
              setTimeout(() => {
                searchInputUUID?.current?.focus();
              }, 100);

              return (
                <BuildUUIDFilter
                  formProps={searchFormProps}
                  refInput={searchInputUUID}
                />
              );
            }}
          />
          <Table.Column
            dataIndex="name"
            key="name"
            title="Name"
            render={(value) => <TextField value={value} />}
            sortOrder={getDefaultSortOrder('name', sorters)}
            sorter
          />
          <Table.Column
            dataIndex="metadataBuildId"
            key="metadataBuildId"
            title="Metadata Build ID"
            render={(value) => <TextField value={value} />}
            sortOrder={getDefaultSortOrder('metadataBuildId', sorters)}
            sorter
            filterDropdown={() => {
              setTimeout(() => {
                searchInputID?.current?.focus();
              }, 100);

              return (
                <MetadataBuildIDFilter
                  formProps={searchFormProps}
                  refInput={searchInputID}
                />
              );
            }}
          />
          <Table.Column
            dataIndex="versionCode"
            key="versionCode"
            title="Version Code"
            render={(value) => (
              <Tag key={value} color="red">
                {value}
              </Tag>
            )}
            sortOrder={getDefaultSortOrder('versionCode', sorters)}
            sorter
          />
          <Table.Column
            dataIndex="tags"
            key="tags"
            title="Tags"
            render={(value) =>
              value !== undefined &&
              value !== null && (
                <Space wrap>
                  {(value as string[]).map((value) => (
                    <Tag key={value} color="blue">
                      {value}
                    </Tag>
                  ))}
                </Space>
              )
            }
            sortOrder={getDefaultSortOrder('tags', sorters)}
            sorter
          />
          <Table.Column
            dataIndex="smokeTestError"
            key="smokeTestError"
            title="Automation Test"
            render={(value) =>
              value !== null &&
              (value === '' ? (
                <Tag color="green">SUCCESS</Tag>
              ) : (
                <Tag color="red" key={value}>
                  {value}
                </Tag>
              ))
            }
            sortOrder={getDefaultSortOrder('smokeTestError', sorters)}
            sorter
          />
          <Table.Column
            dataIndex="createdAt"
            key="createdAt"
            title="Created"
            render={(value) => (
              <TextField value={new Date(value).toLocaleString()} />
            )}
            sortOrder={getDefaultSortOrder('createdAt', sorters)}
            sorter
          />
          <Table.Column<IBuild>
            title="Actions"
            dataIndex="actions"
            fixed="right"
            render={(_, record) => (
              <Space>
                <Tooltip title="Show">
                  <ShowButton
                    hideText
                    recordItemId={record.buildUUID}
                    resourceNameOrRouteName="builds"
                  />
                </Tooltip>
                {canWrite && (
                  <Tooltip title="Edit">
                    <EditButton
                      hideText
                      recordItemId={record.buildUUID}
                      resourceNameOrRouteName="builds"
                    />
                  </Tooltip>
                )}
                <Tooltip title="Download">
                  <Button
                    icon=<DownloadOutlined />
                    onClick={handleDownload(record)}
                  />
                </Tooltip>
                {((canWrite && currentOption()?.canSign === true) ||
                  record.signStatus === 'Initialize' ||
                  record.signStatus === 'Building') && (
                  <Button
                    disabled={record.signStatus === 'Successful'}
                    loading={signButtonLoading}
                    onClick={handleSign(record, apiUrl, setSignButtonLoading)}
                  >
                    {record.signStatus === 'Initialize' ||
                    record.signStatus === 'Building'
                      ? 'Signing'
                      : 'Sign'}
                  </Button>
                )}
                {canWrite && currentOption()?.canOTA === true && (
                  <Button
                    disabled={
                      record.otaStatus === 'Initialize' ||
                      record.otaStatus === 'Building'
                    }
                    onClick={handleOTAChoosePreviousBuild(record)}
                  >
                    {record.otaStatus === 'Initialize' ||
                    record.otaStatus === 'Building'
                      ? 'OTA...'
                      : 'OTA'}
                  </Button>
                )}
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal
        title="Downloads"
        visible={downloadDialogVisible}
        onOk={downloadDialogClose}
        onCancel={downloadDialogClose}
        footer={[
          <Button key="close" type="primary" onClick={downloadDialogClose}>
            CLOSE
          </Button>,
        ]}
      >
        {downloadBuild != null && (
          <DownloadLinkList
            id={downloadBuild.id}
            token={downloadBuild.token}
            buildName={downloadBuild.name}
            pipeline={downloadBuild.pipeline}
          />
        )}
      </Modal>
      <Modal
        visible={otaDialogVisible}
        onOk={otaloadDialogClose}
        onCancel={otaloadDialogClose}
        footer={[
          <Button key="close" type="primary" onClick={otaloadDialogClose}>
            CLOSE
          </Button>,
        ]}
      >
        {otaBuild != null && (
          <OtaBuildList build={otaBuild} close={otaloadDialogClose} />
        )}
      </Modal>
    </>
  );

  function filterProjectName(): string | undefined {
    return filters?.find(
      (value) => (value as LogicalFilter).field === 'projectName',
    )?.value;
  }

  function filterProjectBranch(): string | undefined {
    return filters?.find(
      (value) => (value as LogicalFilter).field === 'projectBranch',
    )?.value;
  }

  function filterTags(): string[] | undefined {
    return filters?.find(
      (value) => (value as LogicalFilter).field === 'tags',
    )?.value;
  }

  function currentOption(): IProjectOption | undefined {
    return projectOptions?.find(
      (value) =>
        value.name === filterProjectName() &&
        value.branch === filterProjectBranch(),
    );
  }

  function handleDownload(
    build: IBuild,
  ): React.MouseEventHandler<HTMLElement> | undefined {
    return () => {
      setDownloadBuild(build);
      downloadDialogShow();
    };
  }

  function handleOTAChoosePreviousBuild(
    build: IBuild,
  ): React.MouseEventHandler<HTMLElement> | undefined {
    return () => {
      setOtaBuild(build);
      otaDialogShow();
    };
  }
};

function getTagOption(
  apiUrl: string,
  id: number,
  setTagOptions: React.Dispatch<
    React.SetStateAction<DefaultOptionType[] | undefined>
  >,
): void {
  axiosInstance
    .get<ITagOption>(`${apiUrl}/pipelines/${id}/tag-options`)
    .then((resp) => {
      setTagOptions(
        resp.data.data?.map((value) => ({ value, label: value })),
      );
    })
    .catch(() => message.error('setTagOptions error'));
}
