import { IResourceComponentsProps, useShow } from '@refinedev/core';
import { Show } from '@refinedev/antd';

import { IRole } from '../../interfaces';
import React from 'react';
import { PipelineList } from '../pipelines';

export const RoleShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IRole>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show isLoading={isLoading} title={record?.displayName}>
      <PipelineList />
    </Show>
  );
};
