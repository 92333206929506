import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
  usePermissions,
} from '@refinedev/core';
import {
  List,
  TextField,
  useTable,
  getDefaultSortOrder,
  EditButton,
  ShowButton,
  DeleteButton,
} from '@refinedev/antd';
import { Table, Space, Tooltip, InputRef } from 'antd';

import { IRole, IFilterVariables } from '../../interfaces';
import React, { useRef } from 'react';
import { SyncOutlined } from '@ant-design/icons';
import { NameFilter } from 'pages/builds/common/name_filter';

export const RoleList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorters, searchFormProps } = useTable<
    IRole,
    HttpError,
    IFilterVariables
  >({
    onSearch: (params: IFilterVariables) => {
      const filters: CrudFilters = [];
      const { field, query: q } = params;

      filters.push(
        {
          field: 'field',
          operator: 'eq',
          value: field,
        },
        {
          field: 'query',
          operator: 'eq',
          value: q,
        }
      );

      return filters;
    },
  });

  let canAdmin = false;
  const { data: permissionsData } = usePermissions<string[]>();
  if (permissionsData !== undefined) {
    for (const i in permissionsData) {
      if (permissionsData[i] === 'frn:role:build:general:admin') {
        canAdmin = true;
        break;
      }
    }
  }

  const searchInput = useRef<InputRef>(null);

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => (
            <TextField value={value.replace('frn:role:build:team:', '')} />
          )}
          defaultSortOrder={getDefaultSortOrder('name', sorters)}
          sorter
          filterDropdown={() => {
            setTimeout(() => {
              searchInput?.current?.focus();
            }, 100);

            return (
              <NameFilter formProps={searchFormProps} refInput={searchInput} />
            );
          }}
        />
        <Table.Column
          dataIndex="displayName"
          key="displayName"
          title="Display Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder('displayName', sorters)}
          sorter
        />
        <Table.Column
          dataIndex="createdTime"
          key="createdTime"
          title="Created"
          render={(value) => (
            <TextField value={new Date(value).toLocaleString()} />
          )}
          defaultSortOrder={getDefaultSortOrder('createdTime', sorters)}
          sorter
        />
        <Table.Column<IRole>
          title="Actions"
          dataIndex="actions"
          fixed="right"
          render={(_, record) => (
            <Space>
              <Tooltip title="Edit">
                <EditButton hideText recordItemId={record.name} />
              </Tooltip>
              <Tooltip title="Access pipelines">
                <ShowButton
                  hideText
                  recordItemId={record.name}
                  icon=<SyncOutlined />
                />
              </Tooltip>
              {canAdmin && (
                <Tooltip title="Delete">
                  <DeleteButton hideText recordItemId={record.name} />
                </Tooltip>
              )}
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
