import { IResourceComponentsProps, useApiUrl } from '@refinedev/core';
import {
  List,
  TextField,
  useTable,
  getDefaultSortOrder,
  ShowButton,
} from '@refinedev/antd';
import { Table, Tag, Space, Button, Modal, message, Tooltip } from 'antd';
import { useModalForm } from '@refinedev/react-hook-form';

import { IBuild, ICustomBuild } from '../../interfaces';
import React, { useState } from 'react';
import handleClipboard from '../../utils';
import { DownloadLinkList } from '../download-links/list';
import {
  DownloadOutlined,
  ShareAltOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { axiosInstance } from '../../rest-data-provider/utils';

export const CustomBuildList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorter } = useTable<ICustomBuild>({
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
  });

  const [downloadBuild, setDownloadBuild] = useState<IBuild>();

  const {
    modal: {
      visible: downloadDialogVisible,
      close: downloadDialogClose,
      show: downloadDialogShow,
    },
  } = useModalForm();

  const apiUrl = useApiUrl();

  return (
    <>
      <List>
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="repo"
            key="repo"
            title="Repo"
            render={(value) => (
              <a href={value} target="_blank" rel="noreferrer">
                {value}
              </a>
            )}
            sortOrder={getDefaultSortOrder('repo', sorter)}
            sorter
          />
          <Table.Column
            dataIndex="ref"
            key="ref"
            title="Ref"
            render={(value) => <TextField value={value} />}
            sortOrder={getDefaultSortOrder('ref', sorter)}
            sorter
          />
          <Table.Column
            dataIndex="platform"
            key="platform"
            title="Platform"
            render={(value) => <TextField value={value} />}
            sortOrder={getDefaultSortOrder('platform', sorter)}
            sorter
          />
          <Table.Column
            dataIndex="component"
            key="component"
            title="Component"
            render={(value) => <TextField value={value} />}
            sortOrder={getDefaultSortOrder('component', sorter)}
            sorter
          />
          <Table.Column
            dataIndex="buildMode"
            key="buildMode"
            title="Build Mode"
            render={(value) => <TextField value={value} />}
            sortOrder={getDefaultSortOrder('buildMode', sorter)}
            sorter
          />
          <Table.Column
            dataIndex="changelogs"
            key="changelogs"
            title="CLs"
            render={(value) =>
              value && (
                <Space wrap>
                  {(value as string[]).map((item) => (
                    <Tag key={item}>
                      <a
                        href={`${window.env.REACT_APP_GERRIT_BASE_URL}/q/${item}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item}
                      </a>
                    </Tag>
                  ))}
                </Space>
              )
            }
          />
          <Table.Column
            dataIndex="status"
            key="status"
            title="Status"
            render={(value) => <Tag color={statusFilter(value)}>{value}</Tag>}
            sortOrder={getDefaultSortOrder('status', sorter)}
            sorter
          />
          <Table.Column
            dataIndex="startedAt"
            key="startedAt"
            title="Started At"
            render={(value) =>
              value && <TextField value={new Date(value).toLocaleString()} />
            }
            sortOrder={getDefaultSortOrder('startedAt', sorter)}
            sorter
          />
          <Table.Column<ICustomBuild>
            title="Actions"
            dataIndex="actions"
            fixed="right"
            render={(_, record) => (
              <Space>
                {record.status !== 'Initialize' &&
                  (record.buildUUID ?? record.buildId) && (
                  <Tooltip title="Show">
                    <ShowButton
                      hideText
                      resourceNameOrRouteName="builds"
                      recordItemId={record.buildUUID ?? record.buildId}
                    />
                  </Tooltip>
                )}
                {record.status !== 'Initialize' && (
                  <Tooltip title="Download">
                    <Button
                      icon=<DownloadOutlined />
                      onClick={handleDownload(record)}
                    />
                  </Tooltip>
                )}
                {record.status !== 'Initialize' && (
                  <Tooltip title="Share">
                    <Button
                      icon=<ShareAltOutlined />
                      onClick={handleShare(record)}
                    />
                  </Tooltip>
                )}
                {record.status === 'Building' && (
                  <Tooltip title="Abort">
                    <Button
                      icon=<StopOutlined />
                      onClick={handleAbort(record)}
                    />
                  </Tooltip>
                )}
              </Space>
            )}
          />
        </Table>
      </List>
      <Modal
        visible={downloadDialogVisible}
        onOk={downloadDialogClose}
        onCancel={downloadDialogClose}
        footer={[
          <Button key="close" type="primary" onClick={downloadDialogClose}>
            CLOSE
          </Button>,
        ]}
      >
        {downloadBuild != null && (
          <DownloadLinkList
            id={downloadBuild.id}
            token={downloadBuild.token}
            buildName={downloadBuild.name}
            pipeline={downloadBuild.pipeline}
          />
        )}
      </Modal>
    </>
  );

    interface Token {
        code: number;
        token: string;
    }

    function handleDownload(
      record: ICustomBuild,
    ): React.MouseEventHandler<HTMLElement> | undefined {
      return () => {
        axiosInstance
          .get<IBuild>(`${apiUrl}/builds/${record.buildUUID}`)
          .then((resp) => {
            setDownloadBuild(resp.data);
            downloadDialogShow();
          });
      };
    }

    function handleShare(
      record: ICustomBuild,
    ): React.MouseEventHandler<HTMLElement> | undefined {
      return () => {
        axiosInstance
          .get<Token>(`${apiUrl}/custom-builds/${record.id}/share`)
          .then((resp) => {
            const shareLink = `${window.location.origin}/builds/show/${
              record.buildUUID ? record.buildUUID : record.buildId
            }?token=${resp.data.token}`;
            handleClipboard(shareLink);
          });
      };
    }

    function handleAbort(
      record: ICustomBuild,
    ): React.MouseEventHandler<HTMLElement> | undefined {
      return () => {
        axiosInstance
          .post(`${apiUrl}/custom-builds/${record.id}/abort`)
          .then(() => {
            message.success(`Abort build ${record.id} success`);
          })
          .catch(() => {
            message.error(`Abort build ${record.id} error`);
          });
      };
    }

    function statusFilter(status: string): string | undefined {
      const statusMap = new Map<string, string>([
        ['Initialize', 'purple'],
        ['Failed', 'red'],
        ['Successful', 'green'],
        ['Building', 'blue'],
      ]);
      return statusMap.get(status);
    }
};
