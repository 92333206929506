import { IResourceComponentsProps, useNavigation } from '@refinedev/core';
import { Edit, useForm, useSelect } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';

import { IRole, IUser } from '../../interfaces';
import React from 'react';

export const RoleEdit: React.FC<IResourceComponentsProps> = () => {
  const { goBack } = useNavigation();
  const { formProps, saveButtonProps } = useForm<IRole>({
    redirect: false,
    onMutationSuccess: () => {
      goBack();
    },
  });

  const { selectProps: userSelectProps } = useSelect<IUser>({
    resource: 'users',
    pagination: {
      current: 1,
      pageSize: 10,
      mode: 'server',
    },
    onSearch: (value: string) => [
      {
        field: 'field',
        operator: 'eq',
        value: 'name',
      },
      {
        field: 'query',
        operator: 'eq',
        value,
      },
    ],
  });

  const { selectProps: roleSelectProps } = useSelect<IRole>({
    resource: 'roles',
    pagination: {
      current: 1,
      pageSize: 10,
      mode: 'server',
    },
    onSearch: (value: string) => [
      {
        field: 'field',
        operator: 'eq',
        value: 'name',
      },
      {
        field: 'query',
        operator: 'eq',
        value,
      },
    ],
  });

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Name" name="name">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Display Name" name="displayName">
          <Input />
        </Form.Item>
        <Form.Item label="Users" name="users">
          <Select mode="multiple" {...userSelectProps} />
        </Form.Item>
        <Form.Item label="Roles" name="roles">
          <Select mode="multiple" {...roleSelectProps} />
        </Form.Item>
      </Form>
    </Edit>
  );
};
