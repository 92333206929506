import type React from 'react';
import {
  useApiUrl,
} from '@refinedev/core';
import {
  TextField,
  useTable,
} from '@refinedev/antd';
import {
  Table,
  Space,
  Button,
  message,
} from 'antd';
import {
  type IBuild,
} from '../../../interfaces';
import { axiosInstance } from 'rest-data-provider/utils';

export const OtaBuildList: React.FC<{ build: IBuild; close: () => void }> = ({
  build,
  close,
}) => {
      interface IOtaResponse {
          id: number;
          name: string;
          buildUUID: number;
          canOTA: boolean;
          createdAt: string;
      }

      const { tableProps } = useTable<IOtaResponse>({
        resource: `builds/${build.id}/ota`,
        hasPagination: false,
      });

      const apiUrl = useApiUrl();
      return (
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="name"
            key="name"
            title="Name"
            render={(value) => <TextField value={value} />}
          />
          <Table.Column
            dataIndex="createdAt"
            key="createdAt"
            title="Created"
            render={(value) => (
              <TextField value={new Date(value).toLocaleString()} />
            )}
          />
          <Table.Column<IOtaResponse>
            title="Actions"
            dataIndex="actions"
            fixed="right"
            render={(_, record) => (
              <Space>
                <Button
                  disabled={!record.canOTA}
                  onClick={handleOTABuild(record.id, false)}
                >
                            OTA
                </Button>
                <Button
                  disabled={!record.canOTA}
                  onClick={handleOTABuild(record.id, true)}
                >
                            OTA FULL BL
                </Button>
              </Space>
            )}
          />
        </Table>
      );

      function handleOTABuild(
        rowId: number,
        fullBL: boolean,
      ): React.MouseEventHandler<HTMLElement> | undefined {
          interface IOTABuildResponse {
              build: IBuild;
          }

          return () => {
            axiosInstance
              .post<IOTABuildResponse>(`${apiUrl}/builds/${build.id}/ota`, {
                previousBuildID: rowId,
                fullBL,
              })
              .then((response) => {
                build.otaJenkinsId = response.data.build.otaJenkinsId;
                build.otaUID = response.data.build.otaUID;
                build.otaStatus = response.data.build.otaStatus;
                close();
                return message.success(`OTA build ${build.name} success`);
              })
              .catch(() => message.error('OTA failed'));
          };
      }
};