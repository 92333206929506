import React from 'react';
import {
  IResourceComponentsProps,
  ResourceProps,
  useApiUrl,
  useCustom,
  useList,
} from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input, Select, Skeleton, Checkbox } from 'antd';

import 'react-mde/lib/styles/css/react-mde-all.css';

import { IManifestOptions } from '../../interfaces';

export const CustomBuildCreate: React.FC<IResourceComponentsProps> = () => {
  const apiUrl = useApiUrl();

  const { data } = useCustom<IManifestOptions>({
    url: `${apiUrl}/custom-builds/manifest-options`,
    method: 'get',
  });

    interface ICustomBuildRequest {
        repo: string;
        ref: string;
        changelogs: string[];
        platform: string;
        component: string;
        buildMode: string;
        isCherryPick: boolean;
    }

    const { formProps, saveButtonProps } = useForm<ICustomBuildRequest>({
      redirect: 'list',
    });

    return data?.data.repo ? (
      <Create saveButtonProps={saveButtonProps} title="Start custom build">
        <Form {...formProps} layout="vertical">
          <Form.Item
            label="Repo"
            name="repo"
            initialValue={data?.data.repo}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled />
          </Form.Item>
          <Form.Item
            label="Platform"
            name="platform"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={
                data?.data.platforms.map((value) => ({
                  label: value,
                  value,
                })) ?? []
              }
            />
          </Form.Item>
          <Form.Item
            label="Component"
            name="component"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={
                data?.data.components.map((value) => ({
                  label: value,
                  value,
                })) ?? []
              }
            />
          </Form.Item>
          <Form.Item
            label="Build Mode"
            name="buildMode"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select
              options={
                data?.data.buildModes.map((value) => ({
                  label: value,
                  value,
                })) ?? []
              }
            />
          </Form.Item>
          <Form.Item
            label="Change Logs"
            name="changeLogs"
            normalize={(value) => String(value).split(',')}
          >
            <Input />
          </Form.Item>
          <RefsSelect name={data.data.repo} />
          <Form.Item
            label="Cherry pick"
            name="isCherryPick"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </Create>
    ) : (
      <Skeleton active />
    );
};

const RefsSelect: React.FC<ResourceProps> = ({ name }) => {
  const { data } = useList({
    resource: 'custom-builds/refs',
    config: {
      filters: [
        {
          field: 'repo',
          operator: 'eq',
          value: name,
        },
      ],
      hasPagination: false,
    },
  });

  return (
    <Form.Item
      label="Ref"
      name="ref"
      rules={[
        {
          required: true,
        },
      ]}
    >
      <Select
        showSearch
        options={
          data?.data.map((value) => ({ label: value, value })) ?? []
        }
      />
    </Form.Item>
  );
};
