import React from 'react';
import { IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input, Select } from 'antd';

import { IUser } from '../../interfaces';

const { Option } = Select;

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IUser>();
  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item label="Email" name="email" rules={[{ required: true }]}>
          <Input inputMode="email" />
        </Form.Item>
        <Form.Item
          label="Roles"
          name="roles"
          rules={[{ required: true }]}
          initialValue={['read']}
        >
          <Select
            mode="multiple"
            defaultValue={['read']}
            optionLabelProp="label"
          >
            <Option value="guest">guest</Option>
            <Option value="read">read</Option>
            <Option value="write">write</Option>
          </Select>
        </Form.Item>
      </Form>
    </Create>
  );
};
