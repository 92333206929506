import Sdk from 'casdoor-js-sdk';

export const ServerUrl = window.env.REACT_APP_API_URL;

const sdkConfig = {
  serverUrl: window.env.REACT_APP_CASDOOR_BASE_URL,
  clientId: window.env.REACT_APP_CASDOOR_CLIENT_ID,
  appName: 'build',
  organizationName: 'cdgfossil',
  redirectPath: '/callback',
};

export const CasdoorSDK = new Sdk(sdkConfig);

export function signin(): void {
  const prevUrl = localStorage.getItem('prevUrl') || '';
  window.location.href = `${
    window.env.REACT_APP_CASDOOR_BASE_URL
  }/login/oauth/authorize?client_id=${
    window.env.REACT_APP_CASDOOR_CLIENT_ID
  }&response_type=code&redirect_uri=${encodeURIComponent(prevUrl)}&scope=read`;
}
