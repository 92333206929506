import {
  type CrudFilters,
  type HttpError,
  type IResourceComponentsProps,
  useApiUrl,
  useCustomMutation,
  usePermissions,
  useShow,
} from '@refinedev/core';
import {
  List,
  TextField,
  useTable,
  getDefaultSortOrder,
  EditButton,
  DeleteButton,
} from '@refinedev/antd';
import {
  Table,
  Checkbox,
  Space,
  Tooltip,
  InputRef,
} from 'antd';
import {
  type IFilterVariables,
  type IRole,
  type IPipeline,
} from '../../interfaces';
import type React from 'react';
import { useRef, useState } from 'react';
import { NameFilter } from 'pages/builds/common/name_filter';

interface permissionMutationResult {
  code: number;
  message: string;
}

export const PipelineList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorters, searchFormProps } = useTable<
    IPipeline,
    HttpError,
    IFilterVariables
  >({
    resource: 'pipelines',
    syncWithLocation: true,
    onSearch: (params: IFilterVariables) => {
      const filters: CrudFilters = [];
      const { field, query: q } = params;

      filters.push(
        {
          field: 'field',
          operator: 'eq',
          value: field,
        },
        {
          field: 'query',
          operator: 'eq',
          value: q,
        }
      );

      return filters;
    },
  });

  const group = useShow<IRole>();
  const [ids] = useState(
    new Set(
      group.queryResult?.data?.data?.permissions?.map((value) => {
        const ids = value.split(':');
        return parseInt(ids[4]);
      }) ?? []
    )
  );
  const apiUrl = useApiUrl();
  const { mutate } = useCustomMutation<permissionMutationResult>();
  const { data: permissionsData } = usePermissions<string[]>();
  let canAdmin = false;
  if (permissionsData !== undefined) {
    canAdmin = permissionsData.find((e) => e === 'frn:role:build:general:admin') !== undefined;
  }

  const searchInput = useRef<InputRef>(null);

  return (
    <List
      canCreate={false}
      title={group.showId === undefined ? 'Pipelines' : ''}
      breadcrumb={null}
    >
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="name"
          key="name"
          title="Name"
          render={(value) => <TextField value={value} />}
          sortOrder={getDefaultSortOrder('name', sorters)}
          sorter
          filterDropdown={() => {
            setTimeout(() => {
              searchInput?.current?.focus();
            }, 100);

            return (
              <NameFilter formProps={searchFormProps} refInput={searchInput} />
            );
          }}
        />
        <Table.Column
          dataIndex="projectName"
          key="projectName"
          title="Project Name"
          render={(value) => <TextField value={value} />}
          sortOrder={getDefaultSortOrder('projectName', sorters)}
          sorter
        />
        <Table.Column
          dataIndex="projectBranch"
          key="projectBranch"
          title="Project Branch"
          render={(value) => <TextField value={value} />}
          sortOrder={getDefaultSortOrder('projectBranch', sorters)}
          sorter
        />
        <Table.Column
          dataIndex="canSign"
          key="canSign"
          title="Can Sign"
          align="center"
          render={(value) => <Checkbox checked={value} />}
        />
        <Table.Column
          dataIndex="canOTA"
          key="canOTA"
          title="Can OTA"
          align="center"
          render={(value) => <Checkbox checked={value} />}
        />
        <Table.Column
          dataIndex="platform"
          key="platform"
          title="Platform"
          render={(value) => <TextField value={value} />}
          sortOrder={getDefaultSortOrder('platform', sorters)}
          sorter
        />
        <Table.Column
          dataIndex="buildMode"
          key="buildMode"
          title="Build Mode"
          render={(value) => <TextField value={value} />}
          sortOrder={getDefaultSortOrder('buildMode', sorters)}
          sorter
        />
        {group.showId !== undefined ? (
          <Table.Column<IPipeline>
            key="access"
            title="Access"
            render={(_, record) => (
              <Checkbox
                checked={ids.has(record.id)}
                onChange={(e) => {
                  mutate(
                    {
                      url: `${apiUrl}/permissions/frn:perm:build:pipelines:${record.id}:::read/roles`,
                      method: e.target.checked ? 'post' : 'delete',
                      values: {
                        role: group.showId,
                      },
                    },
                    {
                      onSuccess: () => {
                        if (e.target.checked) {
                          ids.add(record.id);
                        } else {
                          ids.delete(record.id);
                        }
                      },
                    }
                  );
                }}
              />
            )}
          />
        ) : (
          <Table.Column<IPipeline>
            title="Actions"
            dataIndex="actions"
            render={(_, record) => (
              <Space>
                <Tooltip title="Edit">
                  <EditButton hideText recordItemId={record.id} />
                </Tooltip>
                {canAdmin && (
                  <Tooltip title="Delete">
                    <DeleteButton hideText recordItemId={record.id} />
                  </Tooltip>
                )}
              </Space>
            )}
          />
        )}
      </Table>
    </List>
  );
};
