import { message } from 'antd';

/**
 * @param {string} path
 * @returns {string}
 */
export function getFileName(path: string): string | undefined {
  const arr = path.split('/');
  const fileName = arr.pop();
  return fileName;
}

export default function handleClipboard(text: string): void {
  const dummy = document.createElement('textarea');
  document.body.appendChild(dummy);
  dummy.value = text;
  dummy.select();
  document.execCommand('copy');
  message.success(`Share ${text} successfully`);
  document.body.removeChild(dummy);
}

export function parseMetadata(data: string): { [k: string]: string } {
  const regex = /^\s*([^=]+?)\s*=\s*(.*?)\s*$/;
  const lines = data.split(/[\r\n]+/);
  const value = lines.reduce<{ [k: string]: string }>((result, line) => {
    if (regex.test(line)) {
      const match = line.match(regex);
      if (match) {
        const item1 = match[1];
        const item2 = match[2];
        return { ...result, [item1]: item2 };
      }
    }

    return result;
  }, {});
  return value;
}

export function isIPAFile(path: string): boolean {
  return /\.(ipa)$/.test(path);
}

export function isIphone(): boolean {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) || navigator.userAgent.includes('iPhone')
  );
}
