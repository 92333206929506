import { IResourceComponentsProps } from '@refinedev/core';
import { Create, useForm } from '@refinedev/antd';
import { Form, Input } from 'antd';

import 'react-mde/lib/styles/css/react-mde-all.css';

import { IRole } from '../../interfaces';
import React from 'react';

export const RoleCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm<IRole>();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[
            { required: true },
            {
              pattern: new RegExp(/^[a-z]+[a-z0-9]+(-[a-z0-9]+)*$/),
              message:
                'Start by alphabet, all lowercase, only accept - to split words.',
            },
          ]}
        >
          <Input addonBefore="frn:role:build:team:" />
        </Form.Item>
        <Form.Item
          label="DisplayName"
          name="displayName"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Create>
  );
};
