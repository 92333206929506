import { AuthBindings } from '@refinedev/core';
import Cookies from 'js-cookie';
import { IRole } from 'interfaces';
import { axiosInstance } from 'rest-data-provider/utils';

export const authProvider: AuthBindings = {
  login: async () =>
    Promise.resolve({
      success: true,
    }),
  logout: async () => {
    await axiosInstance.post(
      `${window.env.REACT_APP_CASDOOR_BASE_URL}/api/logout`,
    );
    Cookies.remove('casdoor_session_id');
    localStorage.removeItem('name');
    localStorage.removeItem('email');
    localStorage.removeItem('avatar');
    localStorage.removeItem('roles');
    axiosInstance.defaults.headers.common = {};
    return Promise.resolve({
      success: true,
    });
  },
  onError: async (error) => {
    if (error.status === 401) {
      return Promise.reject();
    }
    return Promise.resolve({});
  },
  check: async () => {
    const resp = await axiosInstance.get(
      `${window.env.REACT_APP_CASDOOR_BASE_URL}/api/get-account`,
    );
    if (resp.data.status === 'ok') {
      localStorage.setItem('email', resp.data.data.email);
      localStorage.setItem('name', resp.data.data.displayName);
      localStorage.setItem('avatar', resp.data.data.avatar);
      localStorage.setItem('roles', JSON.stringify(resp.data.data.roles));
    } else {
      const currentUrl = window.location.href;
      localStorage.setItem('prevUrl', currentUrl);
      return Promise.resolve({ authenticated: false });
    }
    return Promise.resolve({ authenticated: true });
  },
  getPermissions: () => {
    const rolesStr = localStorage.getItem('roles');
    if (rolesStr === null) return Promise.resolve<string[]>([]);
    const roles: IRole[] = JSON.parse(rolesStr);
    return Promise.resolve<string[]>(roles.map((role) => role.name));
  },
  getIdentity: () => {
    const name = localStorage.getItem('name');
    const avatar = localStorage.getItem('avatar');
    if (name) {
      return Promise.resolve({ name, avatar });
    }
    return Promise.reject();
  },
};
