import {
  CrudFilters,
  HttpError,
  IResourceComponentsProps,
} from '@refinedev/core';
import {
  List,
  TextField,
  useTable,
  getDefaultSortOrder,
  EditButton,
} from '@refinedev/antd';
import { Table, Space, Tooltip, InputRef } from 'antd';
import { IUser, IFilterVariables } from '../../interfaces';
import React, { useRef } from 'react';
import { NameFilter } from 'pages/builds/common/name_filter';

export const UserList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps, sorters, searchFormProps } = useTable<
    IUser,
    HttpError,
    IFilterVariables
  >({
    onSearch: (params: IFilterVariables) => {
      const filters: CrudFilters = [];
      const { field, query: q } = params;

      filters.push(
        {
          field: 'field',
          operator: 'eq',
          value: field,
        },
        {
          field: 'query',
          operator: 'eq',
          value: q,
        }
      );

      return filters;
    },
  });

  const searchInput = useRef<InputRef>(null);

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          dataIndex="displayName"
          key="displayName"
          title="Display Name"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder('displayName', sorters)}
          sorter
        />
        <Table.Column
          dataIndex="email"
          key="email"
          title="Email"
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder('name', sorters)}
          sorter
          filterDropdown={() => {
            setTimeout(() => {
              searchInput?.current?.focus();
            }, 100);

            return (
              <NameFilter formProps={searchFormProps} refInput={searchInput} />
            );
          }}
        />

        <Table.Column
          dataIndex="createdTime"
          key="createdTime"
          title="Created"
          render={(value) => (
            <TextField value={new Date(value).toLocaleString()} />
          )}
          defaultSortOrder={getDefaultSortOrder('createdTime', sorters)}
          sorter
        />
        <Table.Column<IUser>
          title="Actions"
          dataIndex="actions"
          fixed="right"
          render={(_, record) => (
            <Space>
              <Tooltip title="Edit">
                <EditButton hideText recordItemId={record.name} />
              </Tooltip>
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
