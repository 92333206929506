import type React from 'react';
import {
  type IResourceComponentsProps,
  useApiUrl,
  useCustom,
  useList,
  useShow,
  usePermissions,
} from '@refinedev/core';
import {
  Show,
  TextField,
  useTable,
  List,
  EditButton,
  RefreshButton,
  ExportButton,
} from '@refinedev/antd';
import { Card, Select, Space, Table, Collapse, Divider, Button, message, Tag, Tooltip } from 'antd';
import { type IBuild, type IDiff, type IMetadata } from 'interfaces';
import { DownloadLinkList } from 'pages/download-links/list';
import { parseMetadata } from 'utils';
import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { QRCodeCanvas } from 'qrcode.react';
import { CheckOutlined, CodeOutlined } from '@ant-design/icons';
import { DefaultOptionType } from 'antd/es/select';
const { Option } = Select;

const { Panel } = Collapse;

export const BuildShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow<IBuild>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const [selectedId, setSelectedId] = useState(0);

  const [diffs, setDiffs] = useState<IDiff[]>();
  const callback = useCallback(
    // The first argument is a function to perform:
    async () => await copy(diffs),
    // The second argument is an array of dependencies
    // needed to detect changes in function arguments:
    [diffs],
  );

  const { data: permissionsData } = usePermissions<string[]>();
  let canWrite = false;
  if (permissionsData !== undefined) {
    canWrite =
      permissionsData.find((e) => e === 'frn:role:build:general:write' || e === 'frn:role:build:general:admin') !== undefined;
  }

  return (
    <Show
      isLoading={isLoading}
      headerButtons={(): JSX.Element => (
        <>
          {canWrite && <EditButton />}
          <RefreshButton />
        </>
      )}
    >
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Detail" key="1">
          {record != null && <BuildDetail build={record} canWrite={canWrite} />}
        </Panel>
      </Collapse>
      <Divider />
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Download" key="1">
          {record != null && (
            <DownloadLinkList
              id={record.id}
              buildName={record.name}
              token={record.token}
              pipeline={record.pipeline}
            />
          )}
        </Panel>
      </Collapse>
      <Divider />
      {record && (
        <Card
          title=<BeforeSelect
            build={record}
            setSelectedId={setSelectedId}
            triggerExport={callback}
          />
        >
          {selectedId && (
            <ChangeList
              id={record.id}
              compareId={selectedId}
              token={record.token}
              setData={setDiffs}
            />
          )}
        </Card>
      )}
    </Show>
  );
};

const BuildDetail: React.FC<{ build: IBuild, canWrite: boolean }> = ({ build, canWrite }) => {
  const apiUrl = useApiUrl();
  const { data } = useCustom<IMetadata>({
    url: `${apiUrl}/builds/${build.id}/metadata`,
    method: 'get',
    config: {
      filters: [
        {
          field: 'token',
          operator: 'eq',
          value: `${build.token}`,
        },
      ],
    },
  });

  const [isCopied, setIsCopied] = useState(false);

  return (
    <table id="build-detail">
      <tr>
        <td
          style={{
            textAlign: 'end',
            fontWeight: 'bold',
            padding: '8px',
          }}
        >
          Build ID:
        </td>
        <td>{build.id}</td>
      </tr>
      <tr>
        <td
          style={{
            textAlign: 'end',
            fontWeight: 'bold',
            padding: '8px',
          }}
        >
          Build Unique ID:
        </td>
        <td>{build.buildUUID}</td>
      </tr>
      <tr>
        <td
          style={{
            textAlign: 'end',
            fontWeight: 'bold',
            padding: '8px',
          }}
        >
          Build Name:
        </td>
        <td>{build.name}</td>
      </tr>
      {data?.data != null && (
        <tr>
          <td
            style={{
              textAlign: 'end',
              fontWeight: 'bold',
              padding: '8px',
            }}
          >
            Build Pipeline:
          </td>
          <Pipeline data={data.data.content} />
        </tr>
      )}
      {canWrite && (
        <tr>
          <td
            style={{
              textAlign: 'end',
              fontWeight: 'bold',
              padding: '8px',
            }}
          >
            Edit Pipeline:
          </td>
          <td>
            <Link to={`/pipelines/edit/${build.pipelineID}`}>
              {build.pipelineID}
            </Link>
          </td>
        </tr>
      )}
      <tr>
        <td style={{ textAlign: 'end', fontWeight: 'bold', padding: '8px' }}>
          Metadata Build ID:
        </td>
        <td>{build.metadataBuildId}</td>
      </tr>
      <tr>
        <td
          style={{
            textAlign: 'end',
            fontWeight: 'bold',
            padding: '8px',
          }}
        >
          Build Date:
        </td>
        <td>{new Date(build.createdAt).toLocaleString()}</td>
      </tr>
      <tr>
        <td
          style={{
            textAlign: 'end',
            fontWeight: 'bold',
            padding: '8px',
          }}
        >
          Project:
        </td>
        <td>{build.project}</td>
      </tr>
      <tr>
        <td
          style={{
            textAlign: 'end',
            fontWeight: 'bold',
            padding: '8px',
          }}
        >
          Branch:
        </td>
        <td>{build.branch}</td>
      </tr>
      <tr>
        <td style={{ textAlign: 'end', fontWeight: 'bold', padding: '8px' }}>
          Tags:
        </td>
        <td>
          {build.tags.map((tag, index) => (
            <Tag key={index} color="purple">
              {tag}
            </Tag>
          ))}
        </td>
      </tr>
      {build.versionCode != undefined && (
        <tr>
          <td style={{ textAlign: 'end', fontWeight: 'bold', padding: '8px' }}>
            Version Code:
          </td>
          <td>
            <Tag color="red">{build.versionCode}</Tag>
          </td>
        </tr>
      )}
      <tr>
        <td style={{ textAlign: 'end', fontWeight: 'bold', padding: '8px' }}>
          Share build:
        </td>
        <td>
          <Space direction='vertical'>
            <QRCodeCanvas
              value={`${window.location.origin}/builds/show/${build.buildUUID}`}
            />
            <Space>
              <a href={`${window.location.origin}/builds/show/${build.buildUUID}`}>{window.location.origin}/builds/show/{build.buildUUID}</a>
              <Button
                key="copy"
                type="default"
                icon={
                  isCopied ? (
                    <CheckOutlined
                      style={{ color: 'green' }}
                    />
                  ) : (
                    <CodeOutlined />
                  )
                }
                onClick={() => {
                  if (typeof navigator !== 'undefined') {
                    navigator.clipboard.writeText(`${window.location.origin}/builds/show/${build.buildUUID}`);
                    setIsCopied(true);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 1000);
                  }
                }}
              >
                {isCopied ? 'Copied' : 'Copy'}
              </Button>
            </Space>
          </Space>
        </td>
      </tr>
    </table>
  );
};

const Pipeline: React.FC<{ data: string }> = ({ data }) => {
  const b = atob(data);
  const buildMeta = { ...parseMetadata(b) };
  return (
    <Button
      type="link"
      onClick={(): void => {
        openLink(buildMeta.BUILD_URL);
      }}
    >
      {jenkinsLink()}
    </Button>
  );

  function jenkinsLink(): string {
    const regex = /^.*(\/.+\/.+\/)$/;
    if (regex.test(buildMeta.BUILD_URL)) {
      const match = buildMeta.BUILD_URL.match(regex);
      if (match !== null && match.length > 1) {
        return match[1];
      }
    }
    return 'N/A';
  }

  function openLink(url: string): void {
    if (url !== 'N/A') {
      window.open(url, '_blank');
    }
  }
};

const BeforeSelect: React.FC<{
  build: IBuild;
  setSelectedId: React.Dispatch<React.SetStateAction<number>>;
  triggerExport: () => Promise<void>;
}> = ({ build, setSelectedId, triggerExport }) => {
  const { data } = useList<IBuild>({
    resource: `builds/${build.id}/before`,
    config: {
      hasPagination: false,
      filters: [
        {
          field: 'token',
          operator: 'eq',
          value: `${build.token}`,
        },
      ],
    },
  });

  useEffect(() => {
    if (data?.data) {
      setSelectedId(data?.data[0].id);
    }
  }
  , [data?.data]);

  return (
    <Space>
      From
      <b>{build.name}</b>
      {data?.data && (
        <Select
          defaultValue={
            <Option key={`${data.data[0].id}`} value={`${data.data[0].id}`} label="China">
              <Tooltip title={`(${data.data[0].buildUUID})-(${data.data[0].metadataBuildId})`}>
                <span>{`${data.data[0].name}`}</span>
              </Tooltip>
            </Option>
          }
          filterOption={true}
          placeholder="Select build"
          dropdownMatchSelectWidth={false}
          onChange={(_, option): void => {
            setSelectedId((option as DefaultOptionType).key);
          }}
        >
          {
            data?.data?.map((value) => (
              <Option key={`${value.id}`} value={`${value.id}`} label="China">
                <Tooltip title={`(${value.buildUUID})-(${value.metadataBuildId})`}>
                  <span>{`${value.name}`}</span>
                </Tooltip>
              </Option>
            ))
          }
        </Select>
      )}
      <ExportButton
        onClick={(): void => {
          triggerExport().catch(() => {
            message.success('Coppied to the clipboard')
          });
        }}
      />
    </Space>
  );
};

const ChangeList: React.FC<{
  id: number;
  compareId: number;
  token?: string;
  setData: React.Dispatch<React.SetStateAction<IDiff[] | undefined>>;
}> = ({ id, compareId, token, setData }) => {
  const { tableProps, tableQueryResult, setFilters } = useTable<IDiff>({
    resource: `builds/${id}/diff`,
    initialFilter: [
      {
        field: 'buildId',
        operator: 'eq',
        value: compareId,
      },
      {
        field: 'token',
        operator: 'eq',
        value: token,
      },
    ],
  });

  useEffect(() => {
    setData(tableQueryResult.data?.data);
  }, [tableQueryResult.data?.data]);

  useEffect(() => {
    setFilters([
      {
        field: 'buildId',
        operator: 'eq',
        value: compareId,
      },
      {
        field: 'token',
        operator: 'eq',
        value: token,
      },
    ]);
  }, [compareId, token]);

  return (
    <List title="" breadcrumb={null}>
      <Table {...tableProps} rowKey="id">
        <Table.Column
          width={256}
          dataIndex="repo"
          key="repo"
          title="Repo"
          render={(value: string | undefined): React.ReactNode => {
            let link = '';
            if (value !== undefined) {
              if (value.startsWith('https://')) {
                link = value;
              } else {
                link = `https://gerrit-asia.fossil.com/admin/repos/${value}`;
              }
            }
            return (
              <a type="link" href={link}>
                {value}
              </a>
            );
          }}
        />
        <Table.Column
          width={1024}
          align='center'
          dataIndex="message"
          key="message"
          title="Commit Message"
          render={(value): JSX.Element => <TextField value={value} />}
        />
        <Table.Column
          width={256}
          dataIndex="author"
          key="author"
          title="Author"
          render={(value): JSX.Element => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="created"
          key="created"
          title="Created"
          render={(value): JSX.Element => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="modified"
          key="modified"
          title="Modified"
          render={(value): JSX.Element => <TextField value={value} />}
        />
        <Table.Column
          width={256}
          dataIndex="localCL"
          key="localCL"
          title="Local CL"
          render={(value): JSX.Element => {
            if (value === 'N/A') {
              return <TextField value={value} />;
            }
            return (
              <a type="link" href={value}>
                {value}
              </a>
            );
          }}
        />
        <Table.Column
          width={256}
          dataIndex="googleCL"
          key="googleCL"
          title="Google CL"
          render={(value): JSX.Element => {
            if (value === 'N/A') {
              return <TextField value={value} />;
            }
            return (
              <a type="link" href={value}>
                {value}
              </a>
            );
          }}
        />
      </Table>
    </List>
  );
};

async function copy(items: IDiff[] | undefined): Promise<void> {
  // console.log(`copy: items=${JSON.stringify(items)}`);
  if (items == null) return;
  const replacer = (value: string): string | null =>
    value === null ? '' : value; // specify how you want to handle null values here
  const header = Object.keys(items[0]);
  if (header.length === 0) return;
  const tsv = [
    header.join('\t'), // header row first
    ...items.map((row) =>
      header
        .map((fieldName) =>
          JSON.stringify(
            getProperty(row, fieldName as keyof IDiff),
            replacer,
          ),
        )
        .join('\t'),
    ),
  ].join('\r\n');
  navigator.clipboard.writeText(tsv).then(
    () => {
      message.success('Async: Copying to clipboard was successful!');
    },
    (err) => {
      message.error('Async: Could not copy: ', err);
    },
  );
}

function getProperty<T, K extends keyof T>(obj: T, key: K): T[K] {
  return obj[key]; // Inferred type is T[K]
}
