import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import {
  type CrudFilters,
  type HttpError,
  type LogicalFilter,
  useApiUrl,
  useList,
  usePermissions,
} from '@refinedev/core';
import {
  List,
  TextField,
  useTable,
  getDefaultSortOrder,
  ShowButton,
  EditButton,
} from '@refinedev/antd';
import {
  Table,
  Select,
  Skeleton,
  Form,
  type SelectProps,
  Space,
  Tag,
  Button,
  Modal,
  Tooltip,
  DatePicker,
  InputRef,
  Divider,
} from 'antd';
import {
  CloudDownloadOutlined,
} from '@ant-design/icons';
import {
  type IBuild,
  type IBuildFilterVariables,
  type IPipelineOption,
} from '../../../interfaces';
import { useModalForm } from '@refinedev/react-hook-form';
import { DownloadLinkList } from '../../download-links/list';
import { BuildUUIDFilter, getTagOption, handleSign, MetadataBuildIDFilter, sortAlphabet } from '../common';
import { OtaBuildList } from '../ota';
import { Link } from 'react-router-dom';

const { RangePicker } = DatePicker;

export const PipelineOptions: React.FC = () => {
  const { data, isLoading } = useList<IPipelineOption>({
    resource: 'pipeline-options',
    config: {
      hasPagination: false,
    },
  });

  return isLoading ? (
    <Skeleton active />
  ) : data !== undefined && data.data && data.data.length > 0 ? (
    <PipelineBuildList pipelineOptions={data.data.sort(sortAlphabet)} />
  ) : (
    <div />
  );
};

const PipelineBuildList: React.FC<{ pipelineOptions: IPipelineOption[] }> = ({
  pipelineOptions,
}) => {
  const { tableProps, sorters, searchFormProps, filters } = useTable<
    IBuild,
    HttpError,
    IBuildFilterVariables
  >({
    resource: 'builds',
    syncWithLocation: true,
    initialSorter: [
      {
        field: 'id',
        order: 'desc',
      },
    ],
    initialFilter: [
      {
        field: 'pipelineName',
        operator: 'eq',
        value: pipelineOptions[0]?.name,
      },
    ],
    onSearch: (params: IBuildFilterVariables) => {
      const filters: CrudFilters = [];
      const { pipelineName, tags, field, query, createdAt } = params;

      filters.push(
        {
          field: 'pipelineName',
          operator: 'eq',
          value: pipelineName ?? pipelineOptions[0]?.name,
        },
        {
          field: 'tags',
          operator: 'eq',
          value: tags,
        },
        {
          field: 'field',
          operator: 'eq',
          value: field,
        },
        {
          field: 'query',
          operator: 'eq',
          value: query,
        },
        {
          field: 'createdAt',
          operator: 'gte',
          value: createdAt ? createdAt[0].toISOString() : undefined,
        },
        {
          field: 'createdAt',
          operator: 'lte',
          value: createdAt ? createdAt[1].toISOString() : undefined,
        },
      );

      return filters;
    },
  });

  const [tagOptions, setTagOptions] = useState<SelectProps['options']>([]);
  const apiUrl = useApiUrl();
  useEffect(() => {
    const option = pipelineOptions.find(
      (value) =>
        value.name ===
        filters?.find(
          (value) =>
            (value as LogicalFilter).field === 'pipelineName',
        )?.value,
    );
    if (option !== undefined) {
      getTagOption(apiUrl, option.id, setTagOptions);
    }
  }, [apiUrl, filters, pipelineOptions]);
  const [downloadBuild, setDownloadBuild] = useState<IBuild>();

  const {
    modal: {
      visible: downloadDialogVisible,
      close: downloadDialogClose,
      show: downloadDialogShow,
    },
  } = useModalForm();

  const { data: permissionsData } = usePermissions<string[]>();
  let canWrite = false;
  let canAdmin = false;
  if (permissionsData !== undefined) {
    canAdmin = permissionsData.find((e) => e === 'frn:role:build:general:admin') !== undefined;
    canWrite = canAdmin || permissionsData.find((e) => e === 'frn:role:build:general:write') !== undefined;
  }

  const [signButtonLoading, setSignButtonLoading] = useState(false);

  const {
    modal: {
      visible: otaDialogVisible,
      close: otaloadDialogClose,
      show: otaDialogShow,
    },
  } = useModalForm();
  const [otaBuild, setOtaBuild] = useState<IBuild>();

  const searchInputUUID = useRef<InputRef>(null);
  const searchInputID = useRef<InputRef>(null);

  return (
    <>
      <List canCreate={canAdmin}
        title=<Form {...searchFormProps} key="filter">
          <Space wrap>
            <Form.Item name="pipelineName" label="Pipeline" key="pipelineName">
              <Select
                key="pipelineSelect"
                showSearch
                style={{ minWidth: '128px' }}
                dropdownMatchSelectWidth={false}
                options={pipelineOptions?.map((value) => ({
                  label: value.name,
                  value: value.name,
                }))}
                defaultValue={filterPipelineName() ?? pipelineOptions[0]?.name}
                onChange={(value) => {
                  const newPipelineOption = pipelineOptions.find(
                    (o) => o.name === value
                  );
                  if (newPipelineOption != null) {
                    searchFormProps.form?.setFieldsValue({
                      pipelineName: value,
                      tags: [],
                    });
                    searchFormProps.form?.submit();
                  }
                }}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    {canWrite && <Divider style={{ margin: '8px 0' }} />}
                    {
                      canWrite && <Button
                        type="link"
                        block
                        onClick={() => {
                          searchFormProps.form?.setFieldsValue({
                            pipelineName: '',
                            tags: [],
                          });
                          searchFormProps.form?.submit();
                        }}
                      >
                      All Pipelines
                      </Button>
                    }
                  </>
                )}
              />
            </Form.Item>
            <Form.Item name="tags" label="Tags" key="tags">
              <Select
                key="tagsSelect"
                showSearch
                style={{ minWidth: '128px' }}
                mode="multiple"
                dropdownMatchSelectWidth={false}
                options={tagOptions}
                defaultValue={filterTags()}
                onChange={(value) => {
                  searchFormProps.form?.setFieldsValue({
                    tags: value,
                  });
                  searchFormProps.form?.submit();
                }}
              />
            </Form.Item>
            <Form.Item label="Created At" name="createdAt">
              <RangePicker
                onChange={(dates) => {
                  if (dates === null) return;
                  searchFormProps.form?.setFieldsValue({
                    createdAt: [
                      dates[0]?.startOf('day'),
                      dates[1]?.endOf('day'),
                    ],
                  });
                  searchFormProps.form?.submit();
                }}
              />
            </Form.Item>
          </Space>
        </Form>
        breadcrumb={null}
      >
        <Table {...tableProps} rowKey="id">
          <Table.Column
            dataIndex="buildUUID"
            key="buildUUID"
            title="Build UUID"
            render={(value) => (
              <Link to={`/builds/show/${value}`}> {value} </Link>
            )}
            sortOrder={getDefaultSortOrder('buildUUID', sorters)}
            sorter
            filterDropdown={() => {
              setTimeout(() => {
                searchInputUUID?.current?.focus();
              }, 100);

              return (
                <BuildUUIDFilter
                  formProps={searchFormProps}
                  refInput={searchInputUUID}
                />
              );
            }}
          />
          <Table.Column
            dataIndex="name"
            key="name"
            title="Name"
            render={(value) => <TextField value={value} />}
            sortOrder={getDefaultSortOrder('name', sorters)}
            sorter
          />
          <Table.Column
            dataIndex="metadataBuildId"
            key="metadataBuildId"
            title="Metadata Build ID"
            render={(value) => <TextField value={value} />}
            sortOrder={getDefaultSortOrder('metadataBuildId', sorters)}
            sorter
            filterDropdown={() => {
              setTimeout(() => {
                searchInputID?.current?.focus();
              }, 100);

              return (
                <MetadataBuildIDFilter
                  formProps={searchFormProps}
                  refInput={searchInputID}
                />
              );
            }}
          />
          <Table.Column
            dataIndex="versionCode"
            key="versionCode"
            title="Version Code"
            render={(value) => (
              <Tag key={value} color="red">
                {value}
              </Tag>
            )}
            sortOrder={getDefaultSortOrder('versionCode', sorters)}
            sorter
          />
          <Table.Column
            dataIndex="tags"
            key="tags"
            title="Tags"
            render={(value) =>
              value !== undefined && (
                <Space wrap>
                  {(value as string[]).map((value) => (
                    <Tag key={value} color="blue">
                      {value}
                    </Tag>
                  ))}
                </Space>
              )
            }
            sortOrder={getDefaultSortOrder('tags', sorters)}
            sorter
          />
          <Table.Column
            dataIndex="smokeTestError"
            key="smokeTestError"
            title="Automation Test"
            render={(value) =>
              value !== undefined &&
              (value === '' ? (
                <Tag color="green">SUCCESS</Tag>
              ) : (
                <Tag color="red">{value}</Tag>
              ))
            }
            sortOrder={getDefaultSortOrder('smokeTestError', sorters)}
            sorter
          />
          <Table.Column
            dataIndex="createdAt"
            key="createdAt"
            title="Created"
            render={(value) => (
              <TextField value={new Date(value).toLocaleString()} />
            )}
            sortOrder={getDefaultSortOrder('createdAt', sorters)}
            sorter
          />
          <Table.Column<IBuild>
            title="Actions"
            dataIndex="actions"
            fixed="right"
            render={(_, record) => (
              <Space>
                <Tooltip title="Show">
                  <ShowButton
                    hideText
                    recordItemId={record.buildUUID}
                    resourceNameOrRouteName="builds"
                  />
                </Tooltip>
                {canWrite && (
                  <Tooltip title="Edit">
                    <EditButton
                      hideText
                      recordItemId={record.buildUUID}
                      resourceNameOrRouteName="builds"
                    />
                  </Tooltip>
                )}
                <Tooltip title="Download">
                  <Button
                    icon=<CloudDownloadOutlined />
                    onClick={handleDownload(record)}
                  />
                </Tooltip>
                {((canWrite && currentOption()?.canSign === true) ||
                  record.signStatus === 'Initialize' ||
                  record.signStatus === 'Building') && (
                  <Button
                    disabled={record.signStatus === 'Successful'}
                    loading={signButtonLoading}
                    onClick={handleSign(record, apiUrl, setSignButtonLoading)}
                  >
                    {record.signStatus === 'Initialize' ||
                        record.signStatus === 'Building'
                      ? 'Signing'
                      : 'Sign'}
                  </Button>
                )}
                {canWrite && currentOption()?.canOTA === true && (
                  <Button
                    disabled={
                      record.otaStatus === 'Initialize' ||
                      record.otaStatus === 'Building'
                    }
                    onClick={handleOTAChoosePreviousBuild(record)}
                  >
                    {record.otaStatus === 'Initialize' ||
                      record.otaStatus === 'Building'
                      ? 'OTA...'
                      : 'OTA'}
                  </Button>
                )}
              </Space>
            )}
          />
        </Table>
      </List>

      <Modal
        visible={downloadDialogVisible}
        onOk={downloadDialogClose}
        onCancel={downloadDialogClose}
        footer={[
          <Button key="close" type="primary" onClick={downloadDialogClose}>
            CLOSE
          </Button>,
        ]}
      >
        {downloadBuild != null && (
          <DownloadLinkList
            id={downloadBuild.id}
            token={downloadBuild.token}
            buildName={downloadBuild.name}
            pipeline={downloadBuild.pipeline}
          />
        )}
      </Modal>
      <Modal
        visible={otaDialogVisible}
        onOk={otaloadDialogClose}
        onCancel={otaloadDialogClose}
        footer={[
          <Button key="close" type="primary" onClick={otaloadDialogClose}>
            CLOSE
          </Button>,
        ]}
      >
        {otaBuild != null && (
          <OtaBuildList build={otaBuild} close={otaloadDialogClose} />
        )}
      </Modal>
    </>
  );

  function currentOption(): IPipelineOption | undefined {
    return pipelineOptions.find(
      (value) => value.name === filterPipelineName(),
    );
  }

  function filterPipelineName(): string | undefined {
    return filters?.find(
      (value) => (value as LogicalFilter).field === 'pipelineName',
    )?.value;
  }

  function filterTags(): string[] | undefined {
    return filters?.find(
      (value) => (value as LogicalFilter).field === 'tags',
    )?.value;
  }

  function handleDownload(
    build: IBuild,
  ): React.MouseEventHandler<HTMLElement> | undefined {
    return () => {
      setDownloadBuild(build);
      downloadDialogShow();
    };
  }

  function handleOTAChoosePreviousBuild(
    build: IBuild,
  ): React.MouseEventHandler<HTMLElement> | undefined {
    return () => {
      setOtaBuild(build);
      otaDialogShow();
    };
  }
};