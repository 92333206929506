import React, { useState } from 'react';
import { useSimpleList } from '@refinedev/antd';
import { Button, Space, Upload, message } from 'antd';
import { type DataNode, type TreeProps } from 'antd/lib/tree';
import { IBuildLink, IStringValue } from '../../interfaces';
import { axiosInstance } from 'rest-data-provider/utils';
import { useApiUrl, usePermissions } from '@refinedev/core';
import { isIphone } from 'utils';
import DirectoryTree from 'antd/es/tree/DirectoryTree';
import saveAs from 'file-saver';
import { AndroidFilled, AppleFilled } from '@ant-design/icons';
import { ServerUrl } from 'settings';
import { RcFile } from 'antd/es/upload';

const pause = (msec = 500): Promise<void> => new Promise((resolve) => {
  setTimeout(resolve, msec);
});

interface DownloadLinkListProps {
  id: number;
  token?: string;
  buildName?: string;
  pipeline?: string;
}
export const DownloadLinkList: React.FC<DownloadLinkListProps> = ({
  id,
  token,
  // buildName,
  // pipeline,
}: DownloadLinkListProps) => {
  const { listProps, queryResult } = useSimpleList<IStringValue>({
    resource: `builds/${id}/files`,
    hasPagination: false,
    initialFilter: [
      {
        field: 'token',
        operator: 'eq',
        value: token,
      },
    ],
  });

  const root = new FileNode(listProps?.dataSource ?? []);

  const [keys, setKeys] = useState<string[]>([]);

  const onCheck: TreeProps['onCheck'] = (_, info) => {
    setKeys(info.checkedNodes.filter((node) => node.isLeaf).map((node) => {
      return node.key as string;
    }));
  };

  const onSelect: TreeProps['onSelect'] = (_, info) => {
    if (info.node.isLeaf) {
      axiosInstance.post(`${apiUrl}/builds/${id}/sign-urls`, {
        host: ServerUrl,
        token,
        keys: [info.node.key as string],
        isIos: isIphone(),
      })
        .then((resp) => {
          resp.data.data
            .forEach((item: IBuildLink) => {
              if (item.link.startsWith('itms-services://')) {
                const fileLink = document.createElement('a');
                fileLink.href = item.link;
                fileLink.setAttribute('download', item.name);
                document.body.appendChild(fileLink);
                fileLink.click();
              } else {
                window.open(item.link);
              }
            })
        })
        .catch(() => {
          message.error('Sign URLs error');
        });
    }
  };

  const apiUrl = useApiUrl();

  const androidNode = findDataNodeByExt(root, '.apk');
  const iosNode = findDataNodeByExt(root, '.ipa');

  const { data: permissionsData } = usePermissions<string[]>();
  const canAdmin = permissionsData && permissionsData.find((e) => e === 'frn:role:build:general:write' || e === 'frn:role:build:general:admin') !== undefined;

  return (
    <Space direction='vertical' style={{ width: '100%' }}>
      <DirectoryTree
        checkable
        showLine
        showIcon
        onCheck={onCheck}
        onSelect={onSelect}
        treeData={Array.from<DataNode>(root.dataNodes.values())}
      />
      <Space>
        <Button
          onClick={async () => {
            try {
              if (keys.length === 0) return;

              const { data: { data } } = await axiosInstance.post<{
                data: IBuildLink[],
              }>(`${apiUrl}/builds/${id}/sign-urls`, {
                host: ServerUrl,
                token,
                keys: keys,
                isIos: isIphone(),
              });

              let waitStack: Promise<void>[] = []

              for (let i = 0; i < data.length; i++) {
                if (waitStack.length === 3) {
                  await Promise.all(waitStack)
                  waitStack = []
                }

                const downloadItem = (): Promise<void> => new Promise<void>(resolve => {
                  const item = data[i]
                  if (item.link.startsWith('itms-services://')) {
                    const fileLink = document.createElement('a');

                    fileLink.href = item.link;
                    fileLink.setAttribute('download', item.name);
                    document.body.appendChild(fileLink);
                    fileLink.click();

                    pause().then(resolve)
                  } else {
                    fetch(item.link)
                      .then((response) => response.blob())
                      .then((blob) => {
                        saveAs(blob, item.name.split('/').pop())
                        resolve()
                      });
                  }
                })

                waitStack.push(downloadItem())
              }
            } catch {
              message.error('Sign URLs error');
            }
          }}
        >
          Download
        </Button>
        {androidNode && (
          <Button
            onClick={() => {
              axiosInstance.post(`${apiUrl}/builds/${id}/sign-urls`, {
                host: ServerUrl,
                token,
                keys: [androidNode.key as string],
                isIos: isIphone(),
              })
                .then((resp) => {
                  resp.data.data
                    .forEach((item: IBuildLink) => {
                      window.open(item.link);
                    })
                })
                .catch(() => {
                  message.error('Sign URLs error');
                });
            }}
          >
            <AndroidFilled />
          </Button>
        )}
        {iosNode && (
          <Button
            onClick={() => {
              axiosInstance.post(`${apiUrl}/builds/${id}/sign-urls`, {
                host: ServerUrl,
                token,
                keys: [iosNode.key as string],
                isIos: isIphone(),
              })
                .then((resp) => {
                  resp.data.data
                    .forEach((item: IBuildLink) => {
                      if (item.link.startsWith('itms-services://')) {
                        const fileLink = document.createElement('a');
                        fileLink.href = item.link;
                        fileLink.setAttribute('download', item.name);
                        document.body.appendChild(fileLink);
                        fileLink.click();
                      } else {
                        window.open(item.link);
                      }
                    })
                })
                .catch(() => {
                  message.error('Sign URLs error');
                });
            }}
          >
            <AppleFilled />
          </Button>
        )}

        {
          canAdmin &&
          <Button
            onClick={() => {
              if (keys.length === 0) return;
              axiosInstance.post(`${apiUrl}/files/delete`, {
                keys: keys,
              })
                .then(() => {
                  message.success('Delete success');
                  queryResult.refetch();
                })
                .catch(() => {
                  message.error('Sign URLs error');
                });
            }}
          >
            Delete
          </Button>
        }
      </Space>
      {
        canAdmin &&
        <Upload.Dragger
          multiple
          customRequest={
            async (options) => {
              const { file } = options;

              const fmData = new FormData();
              const config = {
                headers: { 'content-type': 'multipart/form-data' },
              };
              fmData.append('id', `${id}`);
              fmData.append('path', (options.file as RcFile).webkitRelativePath);
              fmData.append('file', file);
              try {
                await axiosInstance.post(
                  `${apiUrl}/files/upload`,
                  fmData,
                  config
                );
                message.success('upload success');
                queryResult.refetch();
              } catch (err) {
                message.error('upload failed.');
              }
            }}
          directory
          showUploadList={false}
        >
          <p className="ant-upload-text">
            Drag & drop a file in this area
          </p>
        </Upload.Dragger>
      }
    </Space>
  );
};

// Class recursive build an antd tree data
export class FileNode {
  // use for antd tree data
  dataNodes: Map<string, DataNode>;

  constructor(data: IStringValue[]) {
    this.dataNodes = new Map<string, DataNode>();
    data.forEach((value) => {
      this.insert(value);
    });
    this.dataNodes.forEach((value) => {
      this.trim(value);
    });
  }

  // store all notes in tree by path
  nodes: Record<string, FileNode | undefined> = {};

  // insert node to tree
  private insert(path: IStringValue): void {
    this.add(path.value.split('/'), this, undefined);
  }

  // recusive create node or add leaf
  private add(
    paths: string[],
    parentNode: FileNode,
    parent: DataNode | undefined,
  ): void {
    if (paths.length === 0) return;
    const path = paths[0];
    let dir = parentNode.nodes[path];
    let node = parentNode.dataNodes.get(path);
    if (dir == null) {
      let key = path;
      if (parent != null) key = `${parent?.key}/${path}`;
      node = {
        key,
        title: path,
      };
      parentNode.dataNodes.set(path, node);
      if (parent != null) {
        if (parent.children == null) parent.children = [];
        parent.children.push(node);
      }
      if (paths.length === 1) {
        parentNode.nodes[path] = undefined;
        return;
      }
      dir = new FileNode([]);
    }
    dir.add(paths.slice(1), dir, node);
    parentNode.nodes[path] = dir;
  }

  // merge node if it has only-child
  private trim(node: DataNode): void {
    if (node.children && (node.children.length === 1)) {
      node.title = `${node.title}/${node.children[0].title}`;
      node.key = node.children[0].key;
      node.children = node.children[0].children;
    }
    node.isLeaf = !node.children || node.children.length === 0;

    if (node.isLeaf === true) {
      if ((node.key as string).endsWith('.ipa')) {
        node.icon = <AppleFilled />;
      } else if ((node.key as string).endsWith('.apk')) {
        node.icon = <AndroidFilled />;
      }
    }

    node.children?.forEach((value) => {
      this.trim(value);
    });
  }
}

function findDataNodeByExt(node: FileNode, ext: string): DataNode | undefined {
  return Array.from<DataNode>(node.dataNodes.values()).map((value) => {
    return _findDataNodeByExt(value, ext);
  }).find((value) => value);
}

function _findDataNodeByExt(dataNode: DataNode, ext: string): DataNode | undefined {
  if (dataNode.key && (dataNode.key as string).endsWith(ext)) {
    return dataNode;
  }
  return dataNode.children?.map((value) => _findDataNodeByExt(value, ext)).find((value) => value);
}
