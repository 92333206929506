import { useLogin } from '@refinedev/core';
import { isSilentSigninRequired, SilentSignin } from 'casdoor-react-sdk';
import React from 'react';
import * as Setting from '../settings';

export const Login: React.FC = () => {
  const { mutate: login } = useLogin<boolean>();

  if (isSilentSigninRequired()) {
    // if the `silentSignin` parameter exists, perform silent login processing
    return (
      <SilentSignin
        sdk={Setting.CasdoorSDK}
        isLoggedIn={() => localStorage.getItem('accessToken') !== null}
        handleReceivedSilentSigninSuccessEvent={() => {
          // jump to the home page here and clear silentSignin parameter
          login(true);
        }}
        handleReceivedSilentSigninFailureEvent={() => {
          // prompt the user to log in failed here
          alert('login failed');
        }}
      />
    );
  }

  Setting.signin();
  return (
    <div style={{ height: '100vh', display: 'flex' }}>
      <div style={{ maxWidth: '200px', margin: 'auto' }}>
        <div style={{ marginBottom: '28px' }}>
          <img src="./fossil-group.png" alt="logo" width="200px" />
        </div>
      </div>
    </div>
  );
};
