// "axios" package needs to be installed
import { AxiosInstance } from 'axios';
// "stringify" function is re-exported from "query-string" package by "@refinedev/simple-rest"
import { stringify } from '@refinedev/simple-rest';
import { DataProvider } from '@refinedev/core';
import { axiosInstance, generateSort, generateFilter } from './utils';

export const dataProvider = (
  apiUrl: string,
  httpClient: AxiosInstance = axiosInstance,
): Omit<
    Required<DataProvider>,
    'createMany' | 'updateMany' | 'deleteMany'
> => ({
  getList: async ({ resource, pagination, filters, sorters }) => {
    const url = `${apiUrl}/${resource}`;

    const { current = 1, pageSize = 10 } = pagination ?? {};

    const queryFilters = generateFilter(filters);

    const query: {
            page?: number;
            limit?: number;
            sort?: string;
            order?: string;
        } =
            pagination?.mode !== 'off'
              ? {
                page: current,
                limit: pageSize,
              }
              : {};

    const generatedSort = generateSort(sorters);
    if (generatedSort) {
      const { _sort, _order } = generatedSort;
      query.sort = _sort.join(',');
      query.order = _order.join(',');
    }

    const { data } = await httpClient.get(
      `${url}?${stringify(query)}&${stringify(queryFilters)}`,
    );

    return data;
  },

  getMany: async ({ resource, ids }) => {
    const { data: d } = await httpClient.get(
      `${apiUrl}/${resource}?${stringify({ id: ids })}`,
    );

    const { data } = d;
    return { data };
  },

  create: async ({ resource, variables }) => {
    const url = `${apiUrl}/${resource}`;

    const { data } = await httpClient.post(url, variables);

    return {
      data,
    };
  },

  update: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.patch(url, variables);

    return {
      data,
    };
  },

  getOne: async ({ resource, id }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.get(url);

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables }) => {
    const url = `${apiUrl}/${resource}/${id}`;

    const { data } = await httpClient.delete(url, {
      data: variables,
    });

    return {
      data,
    };
  },

  getApiUrl: () => apiUrl,

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    if (sorters) {
      const generatedSort = generateSort(sorters);
      if (generatedSort) {
        const { _sort, _order } = generatedSort;
        const sortQuery = {
          _sort: _sort.join(','),
          _order: _order.join(','),
        };
        requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
      }
    }

    if (filters) {
      const filterQuery = generateFilter(filters);
      requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    }

    if (query) {
      requestUrl = `${requestUrl}&${stringify(query)}`;
    }

    if (headers) {
      httpClient.defaults.headers.common = {
        ...httpClient.defaults.headers.common,
        ...headers,
      };
    }

    let axiosResponse;
    switch (method) {
    case 'put':
    case 'post':
    case 'patch':
      axiosResponse = await httpClient[method](url, payload);
      break;
    case 'delete':
      axiosResponse = await httpClient.delete(url, {
        data: payload,
      });
      break;
    default:
      axiosResponse = await httpClient.get(requestUrl);
      break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
