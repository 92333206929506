import { type IResourceComponentsProps } from '@refinedev/core';
import { Edit, RefreshButton, TextField, useForm } from '@refinedev/antd';
import { Space, Tag, Form, Input, InputNumber } from 'antd';
import { type IBuild } from '../../interfaces';
import type React from 'react';
import { useEffect, useRef, useState } from 'react';
import { type InputRef } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { StoreValue } from 'antd/es/form/interface';

function CustomInput({
  value,
  onClose,
}: {
  value?: string;
  onClose: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}): JSX.Element {
  return (
    <Tag onClose={onClose} closable>
      {value}
    </Tag>
  );
}

export const BuildEdit: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, formLoading } = useForm<IBuild>({
    redirect: 'show',
  });
  const [inputVisible, setInputVisible] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  const showInput = (): void => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setInputValue(e.target.value);
  };

  return (
    <Edit
      saveButtonProps={saveButtonProps}
      isLoading={formLoading}
      headerButtons={() => <RefreshButton />}
    >
      <Form {...formProps} layout="vertical">
        <Form.List name="tags">
          {(fields, { add, remove }) => {
            const children = fields.map(({ key, name, ...restField }) => (
              <Form.Item key={key} {...restField} name={name}>
                <CustomInput
                  onClose={() => {
                    remove(name);
                  }}
                />
              </Form.Item>
            ));
            return (
              <Space align="baseline">
                <TextField value="Tags: " />
                {children}
                {inputVisible && (
                  <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    className="tag-input"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm(add, fields.length)}
                    onPressEnter={handleInputConfirm(add, fields.length)}
                  />
                )}
                {!inputVisible && (
                  <Tag className="site-tag-plus" onClick={showInput}>
                    <PlusOutlined /> New Tag
                  </Tag>
                )}
              </Space>
            );
          }}
        </Form.List>
        <Form.Item
          style={{ marginTop: '10px' }}
          label="Version Code"
          name="versionCode"
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Edit>
  );

  function handleInputConfirm(
    add: (defaultValue?: StoreValue, insertIndex?: number) => void,
    index: number
  ) {
    return () => {
      if (inputValue !== '') add(inputValue, index);
      setInputVisible(false);
      setInputValue('');
    };
  }
};
